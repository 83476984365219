import React, { useRef, useState } from "react";
import "./SteperFormSection.css";
import JOptimanLogo3d from "../../assets/JOptimanlogo.webp";
import httpClient from "../../_util/api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbars from "../../shared-component/Snackbar/SnackBar";
import { loadStripe } from "@stripe/stripe-js";
import {
  hideLoader,
  setIsLoggedIn,
  setUserDetail,
  showLoader,
} from "../../Store/mainSlice";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Asterisk } from "phosphor-react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";

const Register = () => {
  const [isFlipped, setIsFlipped] = useState(true);
  const snackbar_Ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const userDetails = useSelector((state) => state.mainSlice.userdetail);
  // const userId = useSelector((state)=>state.mainSlice.userdetail.userId)
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [isVerifyOTP, setIsVerifyOTP] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
    OTP: "",
  });
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "lllinois",
    "Indiana",
    "lowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "MaryLand",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [agentData, setAgentData] = useState({
    isApproved: false,
    residentState: "",
    age: "",
    firstName: "",
    lastName: "",
    level: 0,
    agentCarrierNumber: "",
    agentTitle: "",
    agentRole: "",
    agentCode: "",
    email: "",
    confirmEmail: "",
    addressLine1: "",
    city: "",
    state: "",
    zipCode: 0,
    activeLicense: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: 0,
  });

  const handleInputChange = (data, field) => {
    setUserCredentials((prevFormData) => ({ ...prevFormData, [field]: data }));
  };

  const handleInputChangeRegistration = (data, field) => {
    setAgentData((prevFormData) => ({ ...prevFormData, [field]: data }));
  };

  const forgotPasswordHandler = () => {
    setIsForgetPassword(true);
  };

  const nextHandler = async () => {
    dispatch(showLoader());
    const res = await httpClient
      .post("/user/forgetPassword", userCredentials)
      .catch((error) => {
        dispatch(hideLoader());
        snackbar_Ref.current.showMessage(
          "error",
          error?.response.data.message,
          "",
          "i-chk-circle"
        );
      });

    console.log("status", res);
    if (res?.status === 200) {
      dispatch(hideLoader());
      snackbar_Ref.current.showMessage(
        "success",
        res?.data.message,
        "",
        "i-chk-circle"
      );
      setIsForgetPassword(false);
      setIsVerifyOTP(true);
    }

    setIsForgetPassword(false);
    setIsVerifyOTP(true);
  };

  const verifyOTPHandler = async () => {
    dispatch(showLoader());
    const res = await httpClient
      .post("/user/verifyOTP", userCredentials)
      .catch((error) => {
        dispatch(hideLoader());
        snackbar_Ref.current.showMessage(
          "error",
          error?.response.data.message,
          "",
          "i-chk-circle"
        );
      });

    if (res?.status === 200) {
      dispatch(hideLoader());
      snackbar_Ref.current.showMessage(
        "success",
        res?.data.message,
        "",
        "i-chk-circle"
      );
      setIsVerifyOTP(false);
    }
  };

  const loginHandler = async (event) => {
    event.preventDefault(); // Prevents default form submission behavior

    dispatch(showLoader());
    const res = await httpClient
      .post("/user/login", userCredentials)
      .catch((error) => {
        dispatch(hideLoader());
        snackbar_Ref.current.showMessage(
          "error",
          error?.response.data.message,
          "",
          "i-chk-circle"
        );
      });

    console.log("login res", res);

    if (res?.status === 200) {
      dispatch(hideLoader());
      const authToken = res.data.token;
      const isAdmin = res.data.isAdmin ? res.data.isAdmin : false;
      const isFinanceUser = res.data.isFinanceUser
        ? res.data.isFinanceUser
        : false;
      const userId = res.data.userId;
      const firstName = res.data.firstName;
      const lastName = res.data.isAdmin
        ? res.data.lastName
        : res.data.isFinanceUser
        ? res.data.lastName
        : "";
      const adminCode = res.data.isAdmin ? res.data.adminCode : "";
      const agentTitle = res.data.isAdmin
        ? ""
        : res.data.isFinanceUser
        ? ""
        : res.data.agentTitle;
      const agentCode = res.data.isAdmin
        ? ""
        : res.data.isFinanceUser
        ? ""
        : res.data.agentCode;
      const contractLevel = res.data.isAdmin
        ? ""
        : res.data.isFinanceUser
        ? ""
        : res.data.contractLevel;
      const profilePic = res.data.profilePic;
      localStorage.setItem("authToken", authToken);
      localStorage.setItem("isAdmin", isAdmin);
      localStorage.setItem("isFinanceUser", isFinanceUser);
      localStorage.setItem("userId", userId);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("profilePic", profilePic);
      localStorage.setItem("adminCode", adminCode);
      localStorage.setItem("agentTitle", agentTitle);
      localStorage.setItem("agentCode", agentCode);
      localStorage.setItem("contractLevel", contractLevel);
      dispatch(setIsLoggedIn(true));
      dispatch(setUserDetail(res.data));
      snackbar_Ref.current.showMessage(
        "success",
        res?.data.message,
        "",
        "i-chk-circle"
      );
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    }
  };

  // const submitHandler = async () => {
  //   dispatch(showLoader())

  //   const res = await httpClient.post('/agents/addNewAgent', agentData)
  //     .catch((error) => {
  //       dispatch(hideLoader())
  //       snackbar_Ref.current.showMessage("error", error?.response.data.message, "", "i-chk-circle");
  //     })

  //   if (res?.status === 200) {
  //     dispatch(hideLoader())
  //     snackbar_Ref.current.showMessage("success", res?.data.message, "", "i-chk-circle");
  //   }
  // }

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(showLoader());

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      dispatch(hideLoader());
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: agentData.firstName + " " + agentData.lastName,
        email: agentData.email,
      },
    });

    if (error) {
      console.error("[Error]", error);
      dispatch(hideLoader());
      snackbar_Ref.current.showMessage(
        "error",
        error?.response.data.message,
        "",
        "i-chk-circle"
      );
      return;
    }

    const res = await httpClient
      .post("/agents/addNewAgent", {
        ...agentData,
        paymentMethodId: paymentMethod.id,
      })
      .catch((error) => {
        dispatch(hideLoader());
        snackbar_Ref.current.showMessage(
          "error",
          error?.response.data.message,
          "",
          "i-chk-circle"
        );
      });

    if (res?.status === 200) {
      dispatch(hideLoader());
      snackbar_Ref.current.showMessage(
        "success",
        res?.data.message,
        "",
        "i-chk-circle"
      );
    }
  };

  return (
    <div className="registerForm__mianWrapper">
      <div className="registerForm_Container">
        {/* Flip Card Container */}
        <div className="registerForm_firstBox">
          <div>
            {/* Login Back Side */}
            <div className="">
              <div className="registerForm_firstBbox_heading">
                <h1>Agent Login</h1>
                <p>
                  Don’t have a Joptiman account?{" "}
                  <button
                    type="button"
                    // onClick={() => setIsFlipped(false)}
                  >
                    <a href="https://joptimanconsultancy.com/register">
                      <span style={{ color: "#F78B2B" }}>Register Here*</span>
                    </a>
                  </button>
                </p>
              </div>

              <form
                onSubmit={loginHandler}
                method="POST"
                style={{
                  display: isForgetPassword
                    ? "none"
                    : isVerifyOTP
                    ? "none"
                    : "block",
                }}
              >
                <div className="registerForm_wrapper">
                  <div className="registerForm_name">
                    <label htmlFor="">
                      Email/Agent Registration ID{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id=""
                      onChange={(e) =>
                        handleInputChange(e.target.value, "email")
                      }
                    />
                  </div>

                  <div className="registerForm_name">
                    <label htmlFor="">
                      Password <span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      id=""
                      onChange={(e) =>
                        handleInputChange(e.target.value, "password")
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <a
                      href="https://joptimanconsultancy.com/"
                      style={{ cursor: "pointer" }}
                    >
                      <Typography
                        textAlign={"right"}
                        style={{
                          fontSize: "14px",
                          color: "#F08613",
                          cursor: "pointer",
                        }}
                      >
                        Back to Home
                      </Typography>
                    </a>
                    <Typography
                      textAlign={"right"}
                      style={{
                        fontSize: "14px",
                        color: "#F08613",
                        cursor: "pointer",
                      }}
                      onClick={forgotPasswordHandler}
                    >
                      Forgot Your Password?
                    </Typography>
                  </div>
                </div>
                <button className="registerForm_btn_login" type="submit">
                  Login
                </button>
              </form>

              {isForgetPassword && (
                <>
                  <div className="registerForm_wrapper">
                    <label htmlFor="">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id=""
                      onChange={(e) =>
                        handleInputChange(e.target.value, "email")
                      }
                    />
                    <Typography
                      textAlign={"right"}
                      style={{
                        fontSize: "14px",
                        color: "#F08613",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                      onClick={() => setIsForgetPassword(false)}
                    >
                      Back to login
                    </Typography>

                    <button
                      className="registerForm_btn_login"
                      type="submit"
                      onClick={nextHandler}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}

              {isVerifyOTP && (
                <div className="registerForm_wrapper">
                  <label htmlFor="">
                    OTP <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="otp"
                    id=""
                    onChange={(e) => handleInputChange(e.target.value, "otp")}
                  />

                  <label htmlFor="">
                    Password <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id=""
                    onChange={(e) =>
                      handleInputChange(e.target.value, "password")
                    }
                  />

                  <button
                    className="registerForm_btn_login"
                    type="submit"
                    onClick={verifyOTPHandler}
                  >
                    Verify OTP
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Static Right Side Content */}
        <div className="registerForm_secondBox">
          <CustomizedSnackbars ref={snackbar_Ref} />
          <img src={JOptimanLogo3d} alt="" className="registerForm_logo" />
          {/*<div className="registerForm_secondBox_Text">
            <p>JOptiman Consultancy Agent Registration</p>
            <ul>
              <li>
                Join an innovative, client-focused financial consulting team
              </li>
              <li>
                Access exclusive training and professional development
                opportunities
              </li>
            </ul>
            <p>
              If you are an existing JOptiman Consultancy agent, you can
              register a new agent using your current login credentials. This
              single sign-in allows you to oversee multiple agents, including
              your existing account.
            </p>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Register;
