import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from '../components/Dashboard'
import Administration from '../components/Administration'
import Carriers from '../components/Carriers'
import Tools from '../components/Tools'
import Policies from '../components/Policies'
import Statements from '../components/Statements'
import Commissions from '../components/Commissions'
import Recruits from '../components/Recruits'
import AccountDetail from '../components/Account'
import Login from '../components/Login'
import Agents from '../components/Agents'
import AddNewAgent from '../components/Recruits/AddNewAgent'
import AgentDetail from '../components/Agents/detail'
import AddNewPolicy_Agent from '../components/Policies/AddNewPolicy_Agent'
import ApprovePolicy from '../components/Policies/ApprovePolicy'
import CommissionDetail from '../components/Commissions/CommissionDetail'
import StatementDetail from '../components/Statements/detail'
import LinearProgressWithLabel from '../shared-component/ProgressBar'
import AddNewRecruit from '../components/Recruits/AddNewRecruit'
import AdminAccount from '../components/Account/AdminAccount'
import Tutorial from '../components/Tutorial'
import Register from '../components/Register'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Outlet } from 'react-router-dom';
// const stripePromise  = await loadStripe('pk_live_51P0u77GOahcFbzCKLkbqjCi1jAI8Uo6dCc3WtkC5Lv9AaOaQH5T3jxKkl0e4EcCT3RSYmI2kWOi9m9yZIi8E9zBE00DpM8g2oV');
const stripePromise  = await loadStripe('pk_test_51PmJIsH3oiHXuOSm93vCbDbLa4BwHeyZbrHeKm0wYaXLRYWtgoRjoGeJN3na6I9hHyO6np9SzmYdhTybOEj8Dnim00vbxuDixD');



const ElementsLayout = () => (
  <Elements stripe={stripePromise}>
    <Outlet />
  </Elements>
);


const AppRouter = () => {
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path='/' element={<Register />}></Route> */}
          <Route element={<ElementsLayout />}>
            <Route path="/" element={<Register />} />
          </Route>
          <Route path='/register' element={<Register />}></Route>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/agentAccountDetails' element={<AccountDetail />}></Route>
          <Route path='/adminAccountDetails' element={<AdminAccount />}></Route>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/administration' element={<Administration />}></Route>
          <Route path='/carriers' element={<Carriers />}></Route>
          <Route path='/tools' element={<Tools />}></Route>
          <Route path='/policies' element={<Policies />}></Route>
          <Route path='/policyDetail/:policyNumber' element={<AddNewPolicy_Agent />}></Route>
          <Route path='/statements' element={<Statements />}></Route>
          <Route path='/statementDetail/:_id' element={<StatementDetail />}></Route>
          <Route path='/commissions' element={<Commissions />}></Route>
          <Route path='/commissionDetail/:_id' element={<CommissionDetail />}></Route>
          <Route path='/recruits' element={<Recruits />}></Route>
          <Route path='/agent' element={<Agents />}></Route>
          <Route path='/tutorials' element={<Tutorial />}></Route>
          <Route path='/addNewRecruit' element={<AddNewRecruit />}></Route>
          <Route path='/addNewRecruit/:_id' element={<AddNewRecruit />}></Route>
          <Route path='/addAgent' element={<AddNewAgent />}></Route>
          <Route path='/addAgent/:id' element={<AddNewAgent />}></Route>
          <Route path='/agentDetail/:_id' element={<AgentDetail />}></Route>
          <Route path='/addNewPolicy_agent' element={<AddNewPolicy_Agent />}></Route>
          <Route path='/approvePolicy' element={<ApprovePolicy />}></Route>
          <Route path='/addNewPolicy_agent/:id' element={<AddNewPolicy_Agent />}></Route>
          <Route path='/approvePolicy/:id' element={<ApprovePolicy />}></Route>
          <Route path='/approvePolicyByPolicyNumber/:policyNumber' element={<ApprovePolicy />}></Route>
          <Route path='/progress' element={<LinearProgressWithLabel />}></Route>
        </Routes>
      </Router>


    </div>
  )
}

export default AppRouter